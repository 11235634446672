<template>
  <div class="et-chart-wrap" :class="{fullscreen: full}">
    <div class="et-chart-setting font-r">
      <div class="time-range">
        <div class="max">
          <span :class="{active: chartype === 3}" @click="setChartType">Time</span>
          <span
            :class="{active: item.value === range && chartype===1}"
            v-for="(item,index) of solution"
            :key="index"
            @click="checkRange(item.value,item.text)"
          >{{item.text}}</span>
          <span class="setting" @click="openSetting">
            <img src="@/assets/images/setting.png" alt />
          </span>
          <span class="indicator" @click="openIndicator">
            <img src="@/assets/images/chartting.png" alt />
          </span>
          <span @click="fullscreen" class="full-screen fullsreen">
            <img src="@/assets/images/fullcreen.png" alt />
          </span>
        </div>
      </div>
    </div>
    <trading-view ref="view" :interval="range" :chartype="chartype" :setStudies="setStudies"></trading-view>
  </div>
</template>
<style src="./chart.scss" lang="scss"></style>

<script>
import TradingView from "./tradingView";
export default {
  name: "ChartContant",
  components: { TradingView },
  data() {
    return {
      range: "15",
      timeline: "15min",
      full: false,
      solution: [
        {
          value: "1",
          text: "1min"
        },
        {
          value: "5",
          text: "5min"
        },
        {
          value: "15",
          text: "15min"
        },
        {
          value: "30",
          text: "30min"
        },
        {
          value: "60",
          text: "60min"
        },
        {
          value: "240",
          text: "4hour"
        },
        {
          value: "1D",
          text: "1day"
        },
        {
          value: "1W",
          text: "1week"
        }
      ],
      chartype: 1,
      chart: "kline"
    };
  },
  computed: {
    curCoin() {
        return this.$store.state.curCoin
    },
    symbol() {
      return this.curCoin.key
    }
  },
  watch: {
      timeline(val) {
        // console.log(val)
        this.$store.commit('setCurTime', val)
      }
  },
  methods: {
    openSetting() {
      window.tvWidget.chart().executeActionById("chartProperties"); // 设置
    },
    openIndicator() {
      window.tvWidget.chart().executeActionById("insertIndicator"); // 指标
    },
    setStudies() {
      let studies = window.tvWidget.chart().getAllStudies();
      studies.forEach(item => {
        if (item.name !== "Volume" && item.id) {
          let ma = window.tvWidget.chart().getStudyById(item.id);
          ma.setVisible(!ma.isVisible());
        }
      });
    },
    setChartType() {
      if (this.chartype !== 3) {
        this.chartype = 3;
        this.range = "1";
        this.$ws.sendMsg(`{"type":"leave","data":"kline:${this.symbol}:${this.timeline}"}`)
        this.timeline = '1min'
        this.setStudies();
        window.tvWidget.chart().setChartType(this.chartype);
        window.tvWidget.chart().setResolution(this.range);
        // this.$ws.subChannel("kline", this.symbol, this.range);
        this.$ws.sendMsg(`{"type":"join","data":"kline:${this.symbol}:${this.timeline}"}`)
        //   window.tvWidget.applyOverrides({
        //     'mainSeriesProperties.areaStyle.color1': '#c1c1c1',
        //     'mainSeriesProperties.areaStyle.color2': '#f2f2f2',
        //     'mainSeriesProperties.areaStyle.linecolor': '#c2c2c2',

        // })
      }
    },
    checkRange(range,timeline) {
      if (window.tvWidget) {
        window.tvWidget.onChartReady(() => {
          this.range = range || window.tvWidget.chart().resolution()
          if (this.chartype !== 1) {
            this.chartype = 1;
            window.tvWidget.chart().setChartType(this.chartype);
            this.setStudies();
          }
          let period = this.range;
          if (this.range === "1D") {
            period = 24 * 60 + "";
          } else if (this.range === "1W") {
            period = 24 * 60 * 7 + "";
          }
          this.$ws.sendMsg(`{"type":"leave","data":"kline:${this.symbol}:${this.timeline}"}`)
          this.timeline = timeline
          // this.$ws.subChannel("kline", this.symbol, period);
          this.$ws.sendMsg(`{"type":"join","data":"kline:${this.symbol}:${this.timeline}"}`)
          window.tvWidget.chart().setResolution(this.range);
          window.tvWidget.chart().resetData();
        });
      }
    },
    fullscreen() {
      this.full = !this.full;
    }
  }
};
</script>
